import type { ReactNode } from "react"
import React, { createContext, useEffect, useMemo, useState } from "react"

import { useRequestInfo } from "../../request-info"
import type { Theme } from "../../server/theme.server"

type DataTheme = {
	[`data-theme`]: Theme
}
type ThemeContextType = {
	isDarkMode: boolean
	setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
	themePreference: string
	setThemePreference: React.Dispatch<React.SetStateAction<string>>
	dataTheme: DataTheme
	currentTheme: string
	theme: Theme
}

export const ThemeContext = createContext({} as ThemeContextType)

type ThemeContextProps = {
	children: ReactNode
}

export function ThemeContextProvider({
	children,
}: Readonly<ThemeContextProps>) {
	const requestInfo = useRequestInfo()
	const theme = requestInfo.userPrefs.theme ?? `light`

	const [themePreference, setThemePreference] = useState<string>(``)
	const [isDarkMode, setIsDarkMode] = useState<boolean>(true)
	const [currentTheme, setCurrentTheme] = useState<string>(``)

	useEffect(() => {
		const getStorageTheme = window.localStorage.getItem(`theme`)

		if (getStorageTheme) {
			setThemePreference(getStorageTheme)
		}
	}, [])

	useEffect(() => {
		if (theme !== null) {
			window.localStorage.setItem(`theme`, theme)
		}
	}, [theme])

	const getStorageTheme = () => {
		const storageTheme = window.localStorage.getItem(`theme`)

		if (
			storageTheme === `dark` ||
			(!storageTheme &&
				window.matchMedia(`(prefers-color-scheme: dark)`).matches)
		) {
			setIsDarkMode(true)
			setThemePreference(`theme-${storageTheme}`)

			document.body.classList.remove(`theme-light`)
			document.body.classList.add(`theme-dark`)
		} else {
			setIsDarkMode(false)
			setThemePreference(`theme-${storageTheme}`)

			document.body.classList.remove(`theme-dark`)
			document.body.classList.add(`theme-light`)
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		getStorageTheme()
	}, [theme])

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const storagedTheme = window.localStorage.getItem(`theme`)
		if (storagedTheme) {
			setCurrentTheme(storagedTheme)
		}
	}, [theme])

	const dataTheme = useMemo(() => {
		return {
			"data-theme": theme ?? `light`,
		}
	}, [theme])

	const contextValue = useMemo(
		() => ({
			isDarkMode,
			setIsDarkMode,
			themePreference,
			setThemePreference,
			dataTheme,
			currentTheme,
			theme,
		}),
		[isDarkMode, themePreference, dataTheme, currentTheme, theme],
	)

	return (
		<ThemeContext.Provider value={contextValue}>
			{children}
		</ThemeContext.Provider>
	)
}
